<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE ITEM OUTGOING DATA</title>
    <section class="content-header">
      <h1>
        Update Item Outgoing Data
        <br />
        <h4>Please update the transaction data outgoing</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Items Expenditure Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Items Outgoing Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Contract Number</label>
                  <input
                    readonly
                    type="text"
                    v-model="nomorkontrak"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Outgoing Number</label>
                  <input
                    type="hidden"
                    v-model="idxoutgoing"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    readonly
                    v-model="outgoingno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="No. Outgoing"
                  />
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Catatan"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Out Date</label>
                  <input
                    type="date"
                    v-model="tglkeluar"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Out Type</label>
                  <select
                    @change="showjenisbc($event)"
                    class="form-control"
                    v-model="outtp"
                    id="jenisout"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datajenisout in jenisout"
                      :key="datajenisout.id"
                      :value="datajenisout.value"
                    >
                      {{ datajenisout.value }}
                    </option>
                  </select>
                </div>
                <div v-if="isshowjenisbc">
                  <div class="form-group">
                    <label for="exampleInputEmail1">BC Type</label>
                    <select class="form-control" v-model="bctype" id="bctype">
                      <option disabled>--Please Select--</option>
                      <option
                        v-for="datatipebc in tipebc"
                        :key="datatipebc.id"
                        :value="datatipebc.value"
                      >
                        {{ datatipebc.value }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Customs Number</label>
                    <input
                      type="text"
                      v-model="nomorkepabeanan"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Customs Number"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Customs Date</label>
                    <input
                      type="date"
                      v-model="tglkepabeanan"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/outgoing">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Items Outgoing Data</h3>
            </div>
            <div class="box-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Request Number </label>
                <input
                  type="text"
                  readonly
                  v-model="nomorrequest"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Order Number</label>
                <select2
                  :data="orderlist"
                  :value="valueorder"
                  @update="updateorder($event)"
                ></select2>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Buyer Name</label>
                <input
                  type="text"
                  readonly
                  v-model="namabuyer"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Product Name</label>
                <input
                  type="text"
                  readonly
                  v-model="namaproduk"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      jenisout: [
        { value: "INTERNAL", id: 1 },
        { value: "EXTERNAL", id: 2 }
      ],
      tipebc: [
        { value: "25", id: 1 },
        { value: "261", id: 2 },
        { value: "27", id: 3 }
      ],
      loading: false,
      isshowjenisbc: false,
      idxoutgoing: "",
      outgoingno: "",
      orderno: "",
      catatan: "",
      tglkeluar: "",
      namabuyer: "",
      namaproduk: "",
      nomorkontrak: "",
      nomorrequest: "",
      outtp: "",
      bctype: "",
      nomorkepabeanan: "",
      tglkepabeanan: "",
      valueorder: "",
      orderlist: []
    };
  },
  created() {
    //this.loadData();
    this.fetchorderbuyerlist();
  },
  methods: {
    showjenisbc(event) {
      const outtype = event.target.value;
      if (outtype == "EXTERNAL") {
        this.isshowjenisbc = true;
      } else {
        this.isshowjenisbc = false;
      }
    },
    updateorder(value) {
      this.valueorder = value;
    },
    fetchorderbuyerlist() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "buyer_order/getdaftar_buyer_order_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.orderlist = resp.data.data;
          this.orderlist.forEach((item) => {
            item.value = item.order_number;
            item.label = item.order_number;
          });
          this.loading = false;
          this.loadData();
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "outgoing_h/getoutgoing_hbyid?id=" +
        this.$route.params.id;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.idxoutgoing = resp.data.data.id;
          this.outgoingno = resp.data.data.outgoing_number;
          this.valueorder = resp.data.data.order_number;
          this.nomorkontrak = resp.data.data.nomor_kontrak;
          this.nomor = resp.data.data.nomor_kontrak;
          this.outtp = resp.data.data.jenis_out;
          this.nomorrequest = resp.data.data.request_number;
          this.catatan = resp.data.data.notes;
          this.tglkeluar = resp.data.data.date_out;
          this.namabuyer = resp.data.data.nama_buyer;
          this.namaproduk = resp.data.data.nama_produk;
          this.nomorkepabeanan = resp.data.data.nomor_kepabeanan;
          this.tglkepabeanan = resp.data.data.tanggal_kepabeanan;
          this.bctype = resp.data.data.jenis_kepabeanan;

          if (this.outtp == "EXTERNAL") {
            this.isshowjenisbc = true;
          } else {
            this.isshowjenisbc = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      var idx = this.idxoutgoing;
      var noout = this.outgoingno;
      var noorder = this.valueorder;
      var catat = this.catatan;
      var keluartgl = this.tglkeluar;
      var nmbuyer = this.namabuyer;
      var nmproduk = this.namaproduk;

      if (noout == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Outgoing Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (keluartgl == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (noorder == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nmbuyer == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nmproduk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          outgoing_number: this.outgoingno,
          order_number: this.valueorder,
          notes: this.catatan,
          date_out: this.tglkeluar,
          kode_user: kodeuser,
          nama_buyer: "",
          nama_produk: "",
          jenis_out: this.outtp,
          nomor_kontrak: this.nomorkontrak,
          request_number: this.nomorrequest,
          nomor_kepabeanan: this.nomorkepabeanan,
          tanggal_kepabeanan: this.tglkepabeanan,
          jenis_kepabeanan: this.bctype
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata =
          this.$apiurl + "outgoing_h/updateoutgoing_h/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIsavedata, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "outgoingheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
